import logo from './images/vasmortgage-logo-2.jpg';
import sliderImage from './images/forsale.jpg';
import about from './images/workspace.jpg';
import about1 from './images/about1.webp';
import about2 from './images/about2.webp';
import about1Alt from './images/foot.jpg';
import about2Alt from './images/for rent.jpg';
import about3 from './images/gallery1.jpg';
import icon1 from './icon/loc.png';
import icon2 from './icon/email.png';
import icon3 from './icon/call.png';
import icon4 from './icon/fb.png';
import icon5 from './icon/tw.png';
import icon6 from './icon/lin(2).png';
import icon7 from './icon/instagram.png';
import icon8 from './images/EqualOpp.png';
import Image from 'react-image-webp';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="header-top">
        <div className="header header-shadow">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col logo_section">
                <div className="full">
                  <div className="center-desk">
                    <div className="logo">
                    <div className="title"><u>VAS Realty LLC</u></div>                      
                    <a href=""><img  alt="" className="" /></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <section className="slider_section">
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">

                <div className="container-fluid padding_dd">
                  <div className="carousel-caption">
                    <div className="row pt-4">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="text-bg">
                          <h1>YOUR JOURNEY BEGINS WITH US!</h1>
                          <ul className='unordered-list' >
                        
                            <li>Perform real estate market analysis</li>
                            <li>A Team available anytime to serve your real estate needs</li>
                            <li>Personalized customer service</li>
                            <li>Stay update with real estate markets, trends and best practices</li>
                            <li>Assist sellers and buyers in marketing and purchasing property</li>
                          </ul>
                          
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="images_box">
                          <figure><img style= {{ borderRadius: "50%"}}src={about1Alt} /></figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
      <div id="about" className="about">
        <div className="container ">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="about-box">
                <h2><strong className="yellow">About us </strong></h2>
                <p className="pad">We have been in the real estate services business for many successful years.
                 We are committed to helping families and individuals to achieve
                the dream of home ownership.  We strive to provide you a very pleasant experience with our team. </p>
                <p className="pad">For us your satisfaction comes first. So expect that we have a team behind that
                cares about you. We will communicate in the best way that’s comfortable for you, providing timely updates
              about the progress of your deal.</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="about-box">
                <figure><img src={about} alt="#" /></figure>
              </div>
            </div>
          </div>
          <div className="row margin-top-20 pt-5">
            <div className="col-md-4">
              <div className="card shadow bg-white rounded" >
                <img className="card-img-top image-size-refine" src={sliderImage} alt="Card image" />
                <div className="card-body min-height-image">
                  <h4 className="card-title">For Sellers</h4>
                  <p className="card-text">We will take over a huge number of tasks  to make sure 
                  that your real estate property is properly evaluated, marketed, negotiated.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow bg-white rounded" >
              <img className="card-img-top image-size-refine" src={about3} alt="Card image" />
                <div className="card-body min-height-image">
                  <h4 className="card-title">For buyer</h4>
                  <p className="card-text">Whether you’re buying your first home or you’ve owned before, let us guide you in
                navigating this major life decision.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow bg-white rounded image-width">
                <img className="card-img-top image-size-refine" src={about2Alt} alt="Card image" />
                <div className="card-body min-height-image">
                  <h4 className="card-title">For Lease & Rent</h4>
                  <p className="card-text">We’re committed to provide you the best price for your property. Start your application today!</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="make">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage center make_text align-center">
                <h2>We will find your<strong className="white_colo"> Dream Home</strong> for you!</h2>
                <p className='hassle'>No hassle, no obligation
              </p>
                <a href="Javascript:void(0)" className='align-center'>Start now</a>
              </div>
            </div>
          </div>

        </div>
      </div>
      <footr>
        <div class="footer ">
          <div class="container">
            <div class="row">

              <div class="col-md-12">
                <form class="news">
                  <input class="newslatter" placeholder="Email" type="text" name=" Email" />
                  <button class="submit">Subscribe</button>
                </form>
              </div>
              <div class="col-md-12">
                <h2>Newsletter</h2>
                <span>THE REALTOR CLIENT NEWSLETTER
                YOU'VE BEEN WAITING FOR
                Stand out from the pack, every quarter, with the
                most advanced realtor client newsletter solution in the industry.
              Incredibly customizable & Great looking. </span>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 ">
                    <div class="address">
                      <h3>Contact us </h3>
                      <ul class="loca">
                        <li>
                          <a><img src={icon1} alt="#" /></a>VAS Realty LLC
                      <br />153 Farmers Folly Dr
                      <br />Mooresville, NC 28117
                    </li>
                        <li>
                          <a><img src={icon2} alt="#" /></a>adalal@vasrealty.com
                    </li>
                        <li>
                          <a><img src={icon3} alt="#" /></a>+1 252-548-9770
                    </li>
                      </ul>
                      <ul class="social_link">
                        <li><a><img src={icon4} /></a></li>
                        <li><a><img src={icon5} /></a></li>
                        <li><a><img src={icon6} /></a></li>
                        <li><a><img src={icon7} /></a></li>
                      </ul>

                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="address">
                      <h3>About</h3>
                      <ul class="Menu_footer">
                        <li class="active"> <a className="color-white">Purchase </a> </li>
                        <li><a className="color-white">Sale</a> </li>
                        <li><a className="color-white">Lease & Rental </a> </li>
                        <li><a className="color-white" href="https://www.vasmortgage.com/">For Mortgage needs Click here </a> </li>
                       
                      </ul>
                      {/*<img  class="roundrect" src={icon8} height="70" width="70" align="middle" hspace="20" vspace="20"/>*/}
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="address">
                      <h3>Disclaimers</h3>
                      <ul class="Menu_footer" >
                        <li><a className="color-white">Legal</a> </li>
                        <li> <a className="color-white">Privacy Policy</a> </li>
                        <li> <a className="color-white" href="https://g.page/r/Cca0eteJSTTJEAg/review"> Review US </a> </li>
                        <li> <a className="color-white"> Licensed in NC, SC </a> </li>
                      </ul>
                    </div>
                  </div>
                  <img  class="roundrect" src={icon8} height="70" width="70" align="middle" hspace="20" vspace="20"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footr>
    </div>
  );
}

export default App;
